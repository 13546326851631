@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.progress-bar {
  background-color: #ff6200;
  height: 20px;
  width: 100%;
  margin-top: 10px;
}

.progress {
  background-color: #4caf50;
  height: 100%;
}

/* Hide navigation buttons by default, including the disabled 'today' button */
.fc-header-toolbar .fc-prev-button,
.fc-header-toolbar .fc-next-button,
.fc-header-toolbar .fc-today-button {
  opacity: 0 !important;  /* Added !important */
  transition: opacity 0.3s;  /* Smooth transition */
}

.fc-toolbar-title{
  font-size: 5em;
}

.fc-header-toolbar .fc-today-button.fc-button-primary-disabled {
  opacity: .0 !important;  /* Added !important */
  transition: opacity 0.3s;  /* Smooth transition */
}

/* Show navigation buttons on hover, including the disabled 'today' button */
.fc-header-toolbar:hover .fc-prev-button,
.fc-header-toolbar:hover .fc-next-button,
.fc-header-toolbar:hover .fc-today-button,
.fc-header-toolbar:hover .fc-today-button.fc-button-primary-disabled {
  opacity: 1 !important;
}





.main-layout {
  display: flex;
  height: 100vh;  
  flex-direction: row; 

}

.content {
  flex: 1;
  overflow-y: auto;}


.venues, .clients {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;

}

.documents {
  display: flex;
  height: 90vh;
  justify-content: center;
  font-size: 3rem;
  margin-left: -25px;
}



input{
  padding: 5px 0px 5px 10px;
}

/* Modern Button Styles */
.button {
  background-color: #086cbd; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #3795f3;
}

/* Modern Input Styles */
.input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.file-selected {
  outline: 4px solid #086cbd;
}

.input-wrapper input[type="file"] {
  margin-right: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  transition: border-color 0.3s;
}


.input-wrapper label {
  font-weight: bold;
}

.inputs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.button-group {
  margin-top: 20px;
}

/* Custom File Input Styles */
.custom-file-input {
  display: none; /* Hide the default input */
}

.custom-file-label {
  padding: 10px 20px;
  background-color: #263238; /* Green */
  color: white;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.custom-file-label:hover {
  background-color: #086cbd;
}

  .fc-toolbar-title {
    font-family: "Roboto", sans-serif;
    text-align: center !important;
    font-weight: bold !important;
  }
