.loginPage {
    background-color: #f7f7f7;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginContainer {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px;
    width: 400px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.logoContainer img {
    width: 150px;
}

h1 {
    text-align: center;
    margin: 20px 0;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputField {
    margin: 2px 0;
    width: 100%;
    & .MuiInputBase-root {
        background-color: transparent;
    }
    & .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    & .MuiInput-underline:before {
        border-bottom: 1px solid #5c6bc0;
    }
    & .MuiInput-underline:after {
        border-bottom: 2px solid #3f51b5;
   
    }
}

.loginButton {
    margin-top: 20px;
    background-color: #5c6bc0;
    color: #ffffff;
    width: 100%;
    transition: background-color 0.2s;

    &:hover {
        background-color: #3f51b5;
    }
}