.navbar {
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #263238;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.nav-menu {
    background-color: #263238;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    transform: translateX(-100%);
    top: 0;
    transition: 850ms;
    box-shadow: 0 2px 4px 0;
}

.nav-menu.active {
    transform: translateX(0);
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    min-width: 100%;
    transition: background-color 0.3s, transform 0.3s;
}

.nav-text a:hover {
    background-color: #1E88E5;
    border-radius: 4px;
}

.nav-menu-items {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 100px;
    background-color: #263238;
    padding: 1em 0;
}

span {
    margin-left: 16px;
    transition: color 0.3s;
}

.mmtitle {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 0px 8px 16px;
    height: 75px;
    color: #f5f5f5;
    font-size: 26px;
    text-align: left;
    margin: 1em 0;
}

.sidebar-footer {
    height: auto; /* Allow the footer to adjust its height */
    display: flex;
    align-items: left;
    justify-content: left; /* Center the logout button horizontally */
    width: 100%;
    bottom: 0;
    list-style-type: none;
    padding: 1em 0;
  }
  
  .sidebar-footer Button {
    /* Style the logout button to match the other buttons */
    width:  70%; /* Make the button fill the available width */
    height: 60px; /* Match the height of other buttons */
    font-size: 18px; /* Match the font size of other buttons */
    /* Other styles to match the appearance of other buttons */
  }